// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("trix")
require("@rails/actiontext")


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


// https://stackoverflow.com/questions/57555708/rails-6-how-to-add-jquery-ui-through-webpacker
// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require("jquery")
require("jquery") // Don't really need to require this...
require("lodash")
require("jquery-ui")
require("jquery.json-viewer/json-viewer/jquery.json-viewer")
require("jquery-sortable-lists")

require("bootstrap")



$(function(){
    // Plain jquery
    $('#fadeMe').fadeOut(5000);

    // jquery-ui
    const availableCities = ['Baltimore', 'New York'];
    $('#cityField').autocomplete( { source: availableCities } );
    $('#calendarField').datepicker( { dateFormat: 'yy-mm-dd' } );
})

//import "../stylesheets/application";
document.addEventListener("turbolinks:load", function() {
    /* #todo
    (function($){
      $(function () {
          $('[data-toggle="tooltip"]').tooltip()
          $('[data-toggle="popover"]').popover()
      })
    })(jQuery)
    */
})

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  //alert("loaded")

  // sortable-list
  $(function(){
    //http://camohub.github.io/jquery-sortable-lists/index.html
    var options = {
      opener: {
        active: true,
        as: 'html',  // or "class"
        close: '<i class="fa fa-minus red"></i>', // or 'fa fa-minus'
        open: '<i class="fa fa-plus"></i>', // or 'fa fa-plus'
        openerCss: {
          'display': 'inline-block', // Default value
          'float': 'left', // Default value
          'width': '18px',
          'height': '18px',
          'margin-left': '-35px',
          'margin-right': '5px',
          'background-position': 'center center', // Default value
          'background-repeat': 'no-repeat' // Default value
        },
        // or like a class. Note that class can not rewrite default values.
        // To rewrite defaults you have to do it through css object.
        openerClass: 'your-class-name'
      }
    }
    $('.sortable-list').sortableLists(options)
  })

})
